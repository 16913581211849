.label {
  color: #fff; /* ラベルテキストの色を指定する */
  font-size: 13px; /* ラベルテキストのサイズを指定する */
  background-color: #006dd9; /* ラベルの背景色を指定する */
  padding: 6px; /* ラベルとテキスト間の余白を指定する */
  border-radius: 10px; /* ラベルボックスの角丸を指定する */
  cursor: pointer; /* マウスカーソルの形を指定する */
}
.input {
}
