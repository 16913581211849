.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.top {
  width: 220px;
  height: 58px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #424242;
  margin: 20px;
}

.set {
  display: flex;
}

.first {
  border-top: solid 1px #e0e0e0;
  width: 50px;
  margin-top: 18px;
}

.second {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.third {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.nodes {
  display: flex;
  flex-wrap: wrap;
  background: #c4c4c4;
  margin: 10px;
}

.wrap {
  padding: 6px;
  margin: 32px;
  text-align: center;
  align-items: center;
}

.avatar {
  position: relative;
}

.avatar_name1 {
  position: absolute;
  overflow-wrap: break-word;
  width: 100px;
  margin-left: -29px;
  background-color: #fa9818;
}

.avatar_name2 {
  position: absolute;
  overflow-wrap: break-word;
  width: 100px;
  margin-left: -29px;
}

.form {
  margin: 20px 20px 0 20px;
  border: none;
  border-bottom: solid 2px;
}

.str1 {
  font-size: 12px;
  line-height: 14px;
  color: #fa9818;
  margin: 0px 20px 20px 20px;
  border: none;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
}

.footer {
  margin-top: １0px;
  display: flex;
  border-top: solid 2px rgba(66, 66, 66, 0.2);
  justify-content: space-between;
  align-items: center;
}

.value {
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  font-size: 12px;
  color: #424242;
  margin-left: 20px;
}

.app {
  background: #fa9818;
  border-radius: 5px;
  color: #ffffff;
  font-family: Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  width: 80px;
  height: 40px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}
