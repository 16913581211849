.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.top {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin: 15px 15px 0 15px;
  position: relative;
}

.time {
  display: flex;
  margin: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(66, 66, 66, 0.5);
}

.str {
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: #424242;
  margin: 15px;
}

.day {
  line-height: 24px;
}
