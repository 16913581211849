.box {
  background: #fbfbfb;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.top {
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  color: #b0b0b0;
}

.head {
  display: flex;
}

.str1 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  width: 80px;
  margin: 0 10px 0 10px;
}

.str2 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 180%;
}
