.header {
  text-align: center;
  margin: auto;
}

.img {
  padding-top: 180px;
}

.note {
  font-size: 10px;
  padding-top: 100px;
}

.create_btn {
  font-size: 14px;
  line-height: 15px;
  text-decoration-line: underline;
  outline: none;
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
  font-family: Acumin Pro;
}

.facebook_btn {
  border: 1px solid #3b5998;
  color: #3b5998;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  margin-bottom: 10px;
  outline: none;
  opacity: 0.5;
  font-family: Acumin Pro;
}

.linkdin_btn {
  border: 1px solid #0177b5;
  color: #0177b5;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  margin-bottom: 10px;
  outline: none;
  opacity: 0.5;
  font-family: Acumin Pro;
}

.email_btn {
  border: 1px solid #424242;
  color: #424242;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  margin-bottom: 10px;
  outline: none;
  font-family: Acumin Pro;
}

.toggle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 20px;
  outline: none;
  border: none;
  background-color: #fff;
  font-family: Acumin Pro;
}
