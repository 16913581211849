.head {
  text-align: center;
  font-family: Acumin Pro;
  font-style: normal;
  width: 400px;
}

.img {
  margin: 180px 0 100px 0;
}

.form {
  width: 200px;
}

.hr {
  color: #424242;
  width: 280px;
}

.input[type="email"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100px;
  border: 0;
  color: #424242;
  padding: 5px;
  width: 300px;
  margin-left: 60px;
}

.input[type="email"]:focus {
  outline: none;
}

.input[type="email"]:focus::after {
  outline: none;
}

.input[type="password"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100px;
  border: 0;
  color: #424242;
  padding: 5px;
  width: 300px;
  margin-left: 60px;
}

.input[type="password"]:focus {
  outline: none;
}

.input[type="password"]:focus::after {
  outline: none;
}

.note {
  display: flex;
  width: 240px;
  margin: auto;
}

.note1 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ff3664;
}

.create_btn {
  background: #ff3664;
  border: 1px solid #424242;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  color: #ffffff;
  cursor: pointer;
}

.have_account {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  color: #424242;
  border: none;
  outline: none;
  background-color: #ffffff;
}
