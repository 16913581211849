.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.top {
  width: 240px;
  height: 58px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #424242;
  margin: 20px;
}

.set {
  display: flex;
}

.first {
  border-top: solid 1px #e0e0e0;
  width: 50px;
  margin-top: 18px;
}

.second {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.smalltop {
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: #424242;
  margin: 10px;
}

.head1 {
  display: flex;
  justify-content: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  width: 340px;
  height: 40px;
  margin: auto;
  padding: 10px;
}

.head2 {
  display: flex;
  justify-content: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  width: 340px;
  height: 60px;
  margin: auto;
  padding: 10px;
  align-items: center;
}

.inhead {
  display: flex;
  position: absolute;
  left: 0;
}

.icon1 {
  color: red;
  margin: auto;
}

.str1 {
  font-weight: 500;
  font-size: 10px;
  line-height: 180%;
  color: #000000;
  width: 200px;
}

.button1 {
  position: absolute;
  right: 0;
  color: rgba(66, 66, 66, 0.2);
  border: none;
  outline: none;
  background: #ffffff;
}

.footer {
  margin-top: 200px;
  display: flex;
  border-top: solid 2px rgba(66, 66, 66, 0.2);
  justify-content: space-between;
  align-items: center;
}

.value {
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  font-size: 12px;
  color: #424242;
  margin-left: 20px;
}

.app {
  background: #ff3664;
  border-radius: 5px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  width: 80px;
  height: 40px;
  border: none;
  margin-right: 20px;
  font-family: Acumin Pro;
}
