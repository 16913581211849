.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.top {
  width: 180px;
  height: 58px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #424242;
  margin: 20px;
}

.set {
  display: flex;
}

.first {
  border-top: solid 1px #e0e0e0;
  width: 50px;
  margin-top: 18px;
}

.second {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.third {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.smalltop {
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: #424242;
  margin: 20px;
  padding-top: 30px;
}

.pos {
  text-align: center;
  padding: 30px;
  background: #fbfbfb;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 280px;
  margin: 15px;
  font-size: 18px;
  color: #424242;
}

/* .footer {
  margin-top: 60px;
  border-top: solid 2px rgba(66, 66, 66, 0.2);
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.app {
  position: absolute;
  right: 0;
  color: #fa9818;
  border: solid 1px;
  padding: 7px 20px 7px 20px;
  background: #ffffff;
  border-radius: 5px;
  font-family: Baskerville;
  font-weight: bold;
  font-size: 12px;
  margin: 20px;
} */
