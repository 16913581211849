.header {
  height: 207.58px;
  background-image: url();
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #424242;
}

.instr {
  margin: auto;
  color: red;
  font-size: 17px;
}

.set {
  display: flex;
  margin: 30px 0 30px 0;
}

.first {
  border-top: solid 1px #e0e0e0;
  width: 50px;
  margin-top: 18px;
}

.second {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.third {
  border-top: solid 1px #e0e0e0;
  width: 90px;
  margin-top: 18px;
}

.head {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.link {
  margin: 10px;
  padding: 10px;
  border: solid 2px red;
}

.str {
  font-size: 12px;
}

.iconhead {
  background-color: #fff;
  text-align: center;
}

.icon {
}
