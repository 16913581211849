.header {
  text-align: center;
  font-family: Acumin Pro;
  font-style: normal;
  width: 400px;
}

.img {
  padding: 180px 0 170px 0;
}

.form {
  width: 200px;
}

.hr {
  color: #424242;
  width: 280px;
}

.input[type="email"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 250px;
  margin-left: 60px;
  border: 0;
  color: #424242;
  padding: 5px;
}

.input[type="email"]:focus {
  outline: none;
}

.input[type="email"]:focus::after {
  outline: none;
}

.note {
  width: 240px;
  margin: auto;
}

.note1 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ff3664;
  margin-bottom: 30px;
}

.send_btn {
  background: #424242;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  color: #ffffff;
}

.cancel_btn {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  color: #424242;
  border: none;
  outline: none;
  background-color: #ffffff;
}
