.header {
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 50px;
}

.body {
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 50px;
}

.header1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background: #424242;
}

.btn1 {
  background: #424242;
  color: #ffffff;
}

.header2 {
  display: flex;
  justify-content: center;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #aaaaaa;
  border-bottom: solid 1px #aaaaaa;
  border-top: solid 1px #aaaaaa;
}

.top {
  display: flex;
  position: absolute;
  right: 0;
}

.btn2 {
  outline: none;
  border: none;
  background: #ffffff;
  color: #aaaaaa;
}

.nodes_head {
  display: flex;
  flex-wrap: wrap;
}

.nodes_box {
  padding: 8px;
  margin: 32px;
  text-align: center;
  align-items: center;
}

.avatar {
  position: relative;
}

.avatar_name {
  position: absolute;
  overflow-wrap: break-word;
  width: 100px;
  margin-left: -29px;
}

/* .nodes2 {
  display: flex;
  flex-wrap: wrap;
  margin: 15px;
}

.node_space2 {
  margin: 5px;
} */
