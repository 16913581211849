.body {
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 50px;
}

.back {
  background: #424242;
}

.img {
  margin: 18px 0 18px 18px;
}

.tag1 {
  display: flex;
  position: relative;
}

.tag2 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.str1 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.str2 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.str3 {
  width: 50%;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.str3_under {
  width: 50%;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border-bottom: solid 3px red;
}

.setting_icon {
  position: absolute;
  right: 0;
  color: #ffffff;
  margin: 10px;
}

.list {
  list-style: none;
  margin-left: -25px;
}

.note {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  color: #aaaaaa;
  margin-left: 10px;
}
