.head {
  text-align: center;
  font-family: Acumin Pro;
  font-style: normal;
  width: 400px;
}

.img {
  margin: 180px 0 130px 0;
}

.form {
  width: 200px;
}

.hr {
  color: #424242;
  width: 280px;
}

.input[type="text"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 70px;
  border: 0;
  color: #424242;
  padding: 5px;
  width: 300px;
  margin-left: 60px;
}

.input[type="text"]:focus {
  outline: none;
}

.input[type="text"]:focus::after {
  outline: none;
}

.note {
  width: 240px;
  margin: auto;
}

.note1 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ff3664;
}

.box {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin: auto;
}

.minibox {
  display: flex;
  font-size: 18px;
}
