.box {
  margin: 20px 30px 20px 30px;
  display: flex;
}

.avatar {
  margin: 22px -15px 22px;
  z-index: 1;
}

.ticket {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  width: 312px;
  height: 86px;
  position: relative;
}

.ticket::after {
  content: "";
  border: 1px dashed #dddddd;
  transform: rotate(90deg);
  width: 78px;
  display: block;
  position: absolute;
  top: 40px;
  left: 210px;
}

.incard {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}

.inincard {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.pos {
  margin: 18px 0 18px 0;
}

.str1 {
  font-family: Baskerville;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  margin: 5px 5px 7px 5px;
}

.str2 {
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  margin: 5px;
}

.str3 {
  font-weight: 300;
  font-size: 7px;
  line-height: 10px;
  color: #424242;
  margin: 6px;
}

.rotate {
  font-family: Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  width: 76px;
  height: 36px;
  color: #424242;
  transform: rotate(-90deg);
  text-align: center;
}

.ver_str {
  font-family: Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-left: -25px;
  margin-right: 25px;
}
