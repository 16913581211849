.head {
  background: #424242;
  display: flex;
  padding: 15px 20px 12px 20px;
}

.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.box {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: #ffffff;
  text-align: center;
  line-height: 60px;
  width: 300px;
  margin: auto;
  border: none;
  outline: none;
  background: transparent;
}

.str {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #424242;
}

.img {
  margin: 20px;
  cursor : pointer;
}

.space {
  height: 9px;
  background: rgba(66, 66, 66, 0.05);
}

.length {
  border: 1px solid rgba(66, 66, 66, 0.2);
  width: 300px;
}

.srt1 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ff3664;
}
