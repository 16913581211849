.header {
  text-align: center;
}

.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.head_top {
  text-align: center;
  color: #ffffff;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  margin: auto;
}

.hukidasi {
  position: relative;
  background: #f4f4f4;
  border-radius: 15px;
  padding: 20px;
  margin: 30px;
  font-family: Noto Sans;
  font-size: 10px;
  line-height: 14px;
  text-align: justify;
}

.hukidasi::before {
  content: "";
  position: absolute;
  left: 20px;
  top: -15px;
  display: block;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f4f4f4;
  border-left: 15px solid transparent;
}

.btn {
  display: flex;
  justify-content: space-between;
  width: 330px;
  margin: auto;
}

.app {
  background: #ff3664;
  border-radius: 5px;
  position: relative;
  color: #ffffff;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  width: 120px;
  height: 24px;
  border: none;
}

.app::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 5px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  margin-left: -15px;
  margin-top: 2px;
}

.ref {
  background: #fa9818;
  border-radius: 5px;
  color: #ffffff;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  width: 120px;
  height: 24px;
  border: none;
}

.ref::before {
  content: "";
  display: inline-block;
  position: absolute;
  border-top: 5px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #ffffff;
  margin-left: -15px;
  margin-top: 2px;
}

.trash {
  background: #dddddd;
  border-radius: 5px;
  width: 60px;
  border: none;
  height: 24px;
}

.h {
  border: 1px solid #dddddd;
  width: 324px;
}

.top {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #aaaaaa;
  margin: 10px;
}

.re {
  margin: auto;
}

.pos {
  display: flex;
  justify-content: center;
  position: relative;
  border-top: 1px solid rgba(66, 66, 66, 0.2);
  border-bottom: 1px solid rgba(66, 66, 66, 0.2);
}

.detail_box {
  overflow-wrap: break-word;
  padding: 20px;
}

.sikiri {
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #aaaaaa;
}

.triangle {
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 0;
  border: none;
  background-color: #ffffff;
  outline: none;
}

.company1 {
  display: flex;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px;
  padding: 5px 10px 5px 10px;
  justify-content: space-between;
}

.list {
  display: flex;
}

.str1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.str_company {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
}

.str2 {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.str3 {
  margin: auto;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.avatar {
  margin: 9px;
}

.company2 {
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px;
  padding: 0px 10px 0px 10px;
  justify-content: space-between;
}

.icon {
  color: #aaaaaa;
  margin: 15px;
}

.v_line_fix {
  width: 5px;
  height: 60px;
  background-color: #e0e0e0;
  margin: -27px 0 -27px 54px;
}
