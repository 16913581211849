.header {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.parent {
  display: flex;
  margin-top: 40px;
}

.child1 {
  flex-grow: 1;
  margin: 5px;
}

.child2 {
  flex-grow: 1;
  margin: 5px;
}

.input[type="password"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 350px;
  border: 0;
  padding: 5px;
  border-bottom: solid 1px #424242;
}

.input[type="password"]:focus {
  outline: none;
}

.input[type="password"]:focus::after {
  outline: none;
}

.space {
  padding: 9px;
}

.head {
  margin: 40px 5px 0 5px;
}

.check {
  display: flex;
  line-height: 42px;
}

.btnpos {
  text-align: center;
}

.tag1 {
  background-color: #ff3664;
  color: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 180px;
  outline: none;
  margin-top: 30px;
}
