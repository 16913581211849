.header {
  text-align: center;
}

.head {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.top {
  font-family: Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 70px;
  text-align: center;
  color: #424242;
}

.pic {
  margin-top: 30px;
  width: 320px;
}

.str {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 180%;
  margin-top: 90px;
}

.time {
  margin: 10px;
}

.next_btn {
  border: 1px solid #3b5998;
  color: #3b5998;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 330px;
  margin: auto;
  margin-bottom: 10px;
  outline: none;
}
