.header {
  display: flex;
  background: #424242;
  height: 60px;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}

.head_btn1 {
  border: none;
  outline: none;
  background: #424242;
  color: #fff;
  font-family: Acumin Pro;
}

.head_btn2 {
  border: none;
  outline: none;
  background: #424242;
  color: #ff3664;
  font-family: Acumin Pro;
  cursor: pointer;
}

.avatar_head {
  height: 50px;
}

.avatar {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  text-align: center;
}

.parent {
  display: flex;
  margin-top: 10px;
}

.child1 {
  flex-grow: 1;
  display: inline-block;
  margin: 5px;
}

.child2 {
  flex-grow: 1;
  margin: 5px;
}

.input[type="text"] {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 350px;
  border: 0;
  color: #424242;
  padding: 5px;
  border-bottom: solid 1px #424242;
}

.input[type="text"]:focus {
  outline: none;
}

.input[type="text"]:focus::after {
  outline: none;
}

.skill_head {
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  flex-wrap: wrap;
}

.skill {
  background: #dddddd;
  border-radius: 50px;
  padding: 0 4px 0 4px;
  margin: 2px;
}

.head {
  margin: 40px 5px 0 5px;
}

.private {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #b0b0b0;
}

.add_exp {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.exp_btn {
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: #b0b0b0;
}

.v_line_fix {
  width: 3px;
  height: 30px;
  background-color: #e0e0e0;
}

.experience1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
}

.experience2 {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #424242;
}

.experience3 {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  color: #b0b0b0;
}

.box {
  margin: 10px;
}

.social_head {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #424242;
  background: #f4f4f4;
  border-radius: 5px;
}

.social_icon1 {
  color: #1877f2;
}

.social_icon2 {
  color: #0077b5;
}

.resume_head {
  font-weight: 500;
  line-height: 14px;
  color: #424242;
  margin-bottom: 10px;
}

.resume_str1 {
  font-weight: 500;
  font-size: 14px;
  color: #424242;
}
