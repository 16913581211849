.btn {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #424242;
  height: 18px;
  background: #dddddd;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  padding: 0 10px 0 10px;
}
