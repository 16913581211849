.img {
  width: 100%;
}

.ava {
  margin: 25px 0 25px 20px;
}

.ten {
  list-style: none;
  margin-left: -20px;
}

.pos {
  display: flex;
}

.str1 {
  font-weight: 600;
  font-size: 12px;
  line-height: 180%;
  color: #424242;
}

.pos1 {
  display: flex;
}

.str2 {
  font-size: 10px;
  line-height: 180%;
  color: #424242;
}

.img1 {
  width: 7px;
  height: 7px;
  margin: 5px 0 5px 0;
}

.str {
  font-size: 12px;
  line-height: 14px;
  text-align: justify;
  color: #424242;
  width: 324px;
  height: 154px;
  margin: auto;
}

.btnpos {
  text-align: center;
}

.tag {
  background-color: #fff;
  border: 1px solid #424242;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  width: 180px;
  outline: none;
}
