.box {
  background: #fbfbfb;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.top {
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  color: #b0b0b0;
}

.list {
  font-family: Acumin Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 180%;
  /* or 25px */

  color: #424242;
}

.flex_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 250px;
}
