.body {
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 50px;
}

.header {
  display: flex;
  justify-content: center;
  height: 60px;
  background: #424242;
  align-items: flex-end;
  text-align: center;
}

.inhead {
  width: 180px;
  border: none;
  outline: none;
  background: #424242;
  color: #fff;
  width: 50%;
}

.inhead_under {
  width: 180px;
  border: none;
  outline: none;
  background: #424242;
  color: #fff;
  border-bottom: solid 3px red;
  width: 50%;
}

.node {
  display: flex;
  margin: 13px;
}

.avatar {
  margin-top: 5px;
}

.note {
  font-weight: 500;
  font-size: 10px;
  line-height: 180%;
  color: #424242;
  margin-left: 10px;
  width: 300px;
}

.time {
  font-size: 9px;
  line-height: 180%;
  color: rgba(66, 66, 66, 0.7);
  margin-left: 10px;
}
