.footer {
  position: fixed;
  margin-top: 20px;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.icon {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 40px;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding: 0 30px 0 30px;
}
