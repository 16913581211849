.header {
  margin-bottom: 60px;
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top {
  background: #424242;
  display: flex;
  justify-content: center;
  position: relative;
  height: 30px;
  padding: 20px;
}

.left {
  position: absolute;
  left: 0;
  outline: none;
  border: none;
  color: #ffffff;
  background: #424242;
}

.center {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.name {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #424242;
  margin: -15px 0 10px 20px;
}

.name_color {
  color: #ffff;
  position: relative;
}

.card {
  background: #f4f4f4;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  background-color: #f4f4f4;
  height: 12px;
}

.inf {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #929292;
  margin: 8px;
}

.size {
  line-height: 25px;
  height: 25px;
}

.head {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
  margin: 7px;
  margin-bottom: 20px;
}

.profession {
  margin: 7px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
}

.skill_head {
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  flex-wrap: wrap;
}

.skill {
  background: #dddddd;
  border-radius: 50px;
  padding: 0 4px 0 4px;
  margin: 2px;
}

.v_line_fix {
  width: 3px;
  height: 30px;
  background-color: #e0e0e0;
}

.experience1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
}

.experience2 {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #424242;
}

.experience3 {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  color: #b0b0b0;
}

.box {
  margin: 10px;
}

.social_head {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #424242;
  background: #f4f4f4;
  border-radius: 5px;
  margin: 5px;
}

.social_icon1 {
  color: #1877f2;
}

.social_icon2 {
  color: #0077b5;
}

.resume_head {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
  display: flex;
}

.resume_str1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
  padding: 0 10px 0 10px;
}

.resume_str2 {
  font-weight: 500;
  font-size: 14px;

  color: #424242;
}
